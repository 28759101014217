import Vue from "vue";
import Router from "vue-router";
const Home = () => import("./pages/Home");
const Popup = () => import("./pages/Popup");

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/popup",
      name: "Popup",
      component: Popup
    },
    {
      path: "/",
      name: "Home",
      component: Home
    }
  ]
});

export default router;
